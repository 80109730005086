import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://201263e8d87139b223ec9b64070d6b32@sentry.cityflo.net/6",
  debug: false,
  environment: "staging",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});
import prefetch from "@astrojs/prefetch/client.js"; prefetch({});